export const usStates = [
  { text: "Alabama", value: "Alabama", key: "AL" },
  { text: "Alaska", value: "Alaska", key: "AK" },
  { text: "Arizona", value: "Arizona", key: "AZ" },
  { text: "Arkansas", value: "Arkansas", key: "AR" },
  { text: "California", value: "California", key: "CA" },
  { text: "Colorado", value: "Colorado", key: "CO" },
  { text: "Connecticut", value: "Connecticut", key: "CT" },
  { text: "Delaware", value: "Delaware", key: "DE" },
  { text: "Florida", value: "Florida", key: "FL" },
  { text: "Georgia", value: "Georgia", key: "GA" },
  { text: "Hawaii", value: "Hawaii", key: "HI" },
  { text: "Idaho", value: "Idaho", key: "ID" },
  { text: "Illinois", value: "Illinois", key: "IL" },
  { text: "Indiana", value: "Indiana", key: "IN" },
  { text: "Iowa", value: "Iowa", key: "IA" },
  { text: "Kansas", value: "Kansas", key: "KS" },
  { text: "Kentucky", value: "Kentucky", key: "KY" },
  { text: "Louisiana", value: "Louisiana", key: "LA" },
  { text: "Maine", value: "Maine", key: "ME" },
  { text: "Maryland", value: "Maryland", key: "MD" },
  { text: "Massachusetts", value: "Massachusetts", key: "MA" },
  { text: "Michigan", value: "Michigan", key: "MI" },
  { text: "Minnesota", value: "Minnesota", key: "MN" },
  { text: "Mississippi", value: "Mississippi", key: "MS" },
  { text: "Missouri", value: "Missouri", key: "MO" },
  { text: "Montana", value: "Montana", key: "MT" },
  { text: "Nebraska", value: "Nebraska", key: "NE" },
  { text: "Nevada", value: "Nevada", key: "NV" },
  { text: "New Hampshire", value: "New Hampshire", key: "NH" },
  { text: "New Jersey", value: "New Jersey", key: "NJ" },
  { text: "New Mexico", value: "New Mexico", key: "NM" },
  { text: "New York", value: "New York", key: "NY" },
  { text: "North Carolina", value: "North Carolina", key: "NC" },
  { text: "North Dakota", value: "North Dakota", key: "ND" },
  { text: "Ohio", value: "Ohio", key: "OH" },
  { text: "Oklahoma", value: "Oklahoma", key: "OK" },
  { text: "Oregon", value: "Oregon", key: "OR" },
  { text: "Pennsylvania", value: "Pennsylvania", key: "PA" },
  { text: "Rhode Island", value: "Rhode Island", key: "RI" },
  { text: "South Carolina", value: "South Carolina", key: "SC" },
  { text: "South Dakota", value: "South Dakota", key: "SD" },
  { text: "Tennessee", value: "Tennessee", key: "TN" },
  { text: "Texas", value: "Texas", key: "TX" },
  { text: "Utah", value: "Utah", key: "UT" },
  { text: "Vermont", value: "Vermont", key: "VT" },
  { text: "Virginia", value: "Virginia", key: "VA" },
  { text: "Washington", value: "Washington", key: "WA" },
  { text: "West Virginia", value: "West Virginia", key: "WV" },
  { text: "Wisconsin", value: "Wisconsin", key: "WI" },
  { text: "Wyoming", value: "Wyoming", key: "WY" }
];

export const canadianProvinces = [
  { text: "Alberta", value: "Alberta", key: "AB" },
  { text: "British Columbia", value: "British Columbia", key: "BC" },
  { text: "Manitoba", value: "Manitoba", key: "MB" },
  { text: "New Brunswick", value: "New Brunswick", key: "NB" },
  { text: "Newfoundland and Labrador", value: "Newfoundland and Labrador", key: "NL" },
  { text: "Nova Scotia", value: "Nova Scotia", key: "NS" },
  { text: "Ontario", value: "Ontario", key: "ON" },
  { text: "Prince Edward Island", value: "Prince Edward Island", key: "PE" },
  { text: "Quebec", value: "Quebec", key: "QC" },
  { text: "Saskatchewan", value: "Saskatchewan", key: "SK" },
  { text: "Northwest Territories", value: "Northwest Territories", key: "NT" },
  { text: "Nunavut", value: "Nunavut", key: "NU" },
  { text: "Yukon", value: "Yukon", key: "YT" }
];
