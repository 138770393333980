<script>
import { SuawModal, SuawTextInput, SuawCheckbox, SuawParagraph } from "@suaw/suaw-component-library";
import { required, email } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";

export default {
  name: "ChangeEmailModal",
  components: { SuawModal, SuawTextInput, SuawCheckbox, SuawParagraph },
  data() {
    return {
      modalSnackbarMessage: null,
      modalSnackbarType: null,
      showPassword: false,
      emailForm: {
        userId: this.$auth.user.id,
        newEmail: "",
        password: ""
      }
    };
  },
  validations: {
    emailForm: {
      password: {
        required
      },
      newEmail: {
        required,
        email
      }
    }
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async verifyPassword() {
      const { userId, password } = this.emailForm;
      const result = await UserApi.verifyPassword(userId, password);
      const isValidPassword = result?.result?.value?.is_valid;
      if (!isValidPassword) {
        this.$root.$emit("universal-error-message", "The password you entered is incorrect.");
      }
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateUserAuthEmail() {
      const { userId, newEmail } = this.emailForm;
      const result = await UserApi.updateUserAuthEmail(userId, newEmail);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSubmitModal() {
      this.$v.emailForm.$touch();
      if (this.$v.emailForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please fill out all fields of form correctly before submitting.");
        return;
      }
      const verifyPasswordResult = await this.verifyPassword();
      const isValidPassword = verifyPasswordResult.result.value.is_valid;
      if (!verifyPasswordResult.success || !isValidPassword) {
        return;
      }

      const updateUserAuthEmailResult = await this.updateUserAuthEmail();
      if (!updateUserAuthEmailResult.success) {
        return;
      }
      this.$root.$emit(
        "universal-success-message",
        `We just sent a verification email to <b>${this.emailForm.newEmail}</b>. To complete your email change, please check your mail and follow the instructions provided.`
      );
      this.$emit("close");
    },
    onExitModal() {
      this.$emit("close");
    }
  }
};
</script>
<template>
  <SuawModal modal-title="Change Email" modal-type="confirmation" :toggle-button-confirm="onSubmitModal" :toggle-button-cancel="onExitModal">
    <SuawParagraph text="A confirmation email will be sent to your new email. To complete the change, click on the <b>Confirm Email Address</b> button in the email." />
    <SuawTextInput
      id="new-email"
      v-model="emailForm.newEmail"
      type="email"
      placeholder=""
      is-required
      label="New Email Address:"
      :state="formFieldState($v, 'emailForm', 'newEmail')"
      :error-message="validateErrors($v.emailForm.newEmail, 'New Email')"
      @blur="$v.emailForm.newEmail.$touch()"
    />
    <SuawTextInput
      id="password"
      v-model="emailForm.password"
      :type="passwordInputType"
      :placeholder="showPassword ? 'Enter Password' : ''"
      label="Current Password:"
      is-required
      :state="formFieldState($v, 'emailForm', 'password')"
      :error-message="validateErrors($v.emailForm.password, 'Password')"
      @blur="$v.emailForm.password.$touch()"
    />
    <SuawCheckbox id="show" v-model="showPassword" label="Show Password" />
  </SuawModal>
</template>
