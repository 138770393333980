<script>
import {
  SuawMainContent,
  SuawForm,
  SuawSummary,
  SuawInputGroup,
  SuawHeading,
  SuawParagraph,
  SuawTextInput,
  SuawSelect,
  SuawButton,
  SuawLabel,
  SuawRadio
} from "@suaw/suaw-component-library";
import { usStates, canadianProvinces } from "../../../../utils/geo/regions.js";
import { CountryOptions, UserSettingsById } from "../../operations.gql";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";
import ChangePasswordModal from "../components/ChangePasswordModal.vue";
import ChangeEmailModal from "../components/ChangeEmailModal.vue";
export default {
  name: "DashboardSettingsEdit",
  components: {
    SuawMainContent,
    SuawForm,
    SuawSummary,
    SuawInputGroup,
    SuawHeading,
    SuawParagraph,
    SuawTextInput,
    SuawSelect,
    SuawButton,
    SuawLabel,
    SuawRadio,
    ChangePasswordModal,
    ChangeEmailModal
  },
  data() {
    return {
      showPasswordModal: false,
      showEmailModal: false,
      resultUserSettingsById: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        is_last_name_hidden: null,
        country: "",
        country_abbrev: "",
        postal_code: "",
        unit_system: "",
        description_json: null,
        avatar_color: "",
        languages: null,
        city: "",
        region: "",
        is_preferred_chapter_discussions: [
          // {
          //   is_preferred: null
          // }
        ],
        is_preferred_series_discussions: [
          // {
          //   is_preferred: null
          // }
        ]
      },
      resultCountryOptions: [],
      editForm: {
        userId: "",
        firstName: "",
        lastName: "",
        isLastNameHidden: null,
        avatarColor: "",
        descriptionJson: null,
        city: null,
        region: null,
        postalCode: "",
        countryAbbrev: "",
        unitSystem: ""
      }
    };
  },
  apollo: {
    resultCountryOptions: {
      query: CountryOptions
    },
    resultUserSettingsById: {
      query: UserSettingsById,
      variables() {
        return {
          user_id: this.$auth.user.id
        };
      },
      update(data) {
        if (data && data.resultUserSettingsById && data.resultUserSettingsById.length > 0) {
          this.populateEditForm(data.resultUserSettingsById[0]);
        }
        return data.resultUserSettingsById?.[0] || this.resultUserSettingsById;
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  validations: {
    editForm: {
      countryAbbrev: {
        required
      },
      postalCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(10)
      }
    }
  },
  computed: {
    countryOptions() {
      return this.resultCountryOptions.length > 0
        ? this.resultCountryOptions.map(option => ({
            text: option.name,
            value: option.abbrev,
            key: option.abbrev
          }))
        : [];
    },
    regionOptions() {
      if (this.editForm.countryAbbrev === "US") {
        return usStates;
      } else if (this.editForm.countryAbbrev === "CA") {
        return canadianProvinces;
      }
      return [];
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    populateEditForm(userData) {
      this.editForm = {
        userId: userData.id ?? "",
        firstName: userData.first_name ?? "",
        lastName: userData.last_name ?? "",
        isLastNameHidden: userData.is_last_name_hidden ?? null,
        countryAbbrev: userData.country_abbrev ?? "",
        postalCode: userData.postal_code ?? "",
        unitSystem: userData.unit_system ?? "",
        descriptionJson: userData.description_json ?? null,
        avatarColor: userData.avatar_color ?? "",
        languages: userData.languages ?? null,
        city: userData.city ?? "",
        region: userData.region ?? ""
      };
    },
    async updateUserProfile() {
      if (this.editForm.countryAbbrev !== "US" && this.editForm.countryAbbrev !== "CA") {
        this.editForm.region = null;
      }
      const { userId, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, unitSystem, descriptionJson, avatarColor, languages, city, region } = this.editForm;
      const result = await UserApi.updateUserProfile(
        userId,
        firstName,
        lastName,
        isLastNameHidden,
        countryAbbrev,
        postalCode,
        unitSystem,
        descriptionJson,
        avatarColor,
        languages,
        city,
        region
      );
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onConfirmClicked() {
      this.$v.editForm.$touch();
      if (this.$v.editForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete required fields of form.");
        return;
      }

      const updateUserProfileResult = await this.updateUserProfile();
      if (!updateUserProfileResult.success) {
        return;
      }

      this.$router.push({ name: "DashboardSettingsView" });
      this.$root.$emit("universal-success-message", "You successfully updated your profile!");
    },
    onCancelClicked() {
      this.$router.push({ name: "DashboardSettingsView" });
    },
    onChangePasswordClicked() {
      this.showPasswordModal = true;
    },
    onChangeEmailClicked() {
      this.showEmailModal = true;
    },
    onExitModal() {
      this.showPasswordModal = false;
      this.showEmailModal = false;
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <ChangePasswordModal v-if="showPasswordModal" @close="onExitModal" />
    <ChangeEmailModal v-if="showEmailModal" @close="onExitModal" />
    <SuawForm>
      <template #form>
        <SuawSummary summary-type="text" heading="Settings" />
        <SuawHeading level="4" content="Account Details" />
        <div>
          <SuawButton class="" size="large" type="ghost-outline" button-text="Change Email" @click="onChangeEmailClicked" />
        </div>
        <div>
          <SuawButton class="" size="large" type="ghost-outline" button-text="Change Password" @click="onChangePasswordClicked" />
        </div>
        <SuawHeading level="4" content="Location" />
        <SuawParagraph text="Entering this information will help us show you the most relevant events near you." />
        <SuawSelect v-if="countryOptions.length > 0" id="country" v-model="editForm.countryAbbrev" label="Country" label-weight="bold" :options="countryOptions" />
        <SuawSelect
          v-if="editForm.countryAbbrev === 'US' || editForm.countryAbbrev === 'CA'"
          id="region"
          v-model="editForm.region"
          :options="regionOptions"
          label="State/Province (optional)"
          label-weight="bold"
        />
        <SuawTextInput id="city" v-model="editForm.city" type="text" placeholder="City" label="City (optional)" label-weight="bold" />
        <SuawTextInput
          id="postalcode"
          v-model="editForm.postalCode"
          type="text"
          placeholder="Postal Code"
          label="Postal Code"
          label-weight="bold"
          is-required
          :state="formFieldState($v, 'editForm', 'postalCode')"
          :error-message="validateErrors($v.editForm.postalCode, 'Postal code')"
          @blur="$v.editForm.postalCode.$touch()"
        />
        <SuawInputGroup direction="column" group-gap="base">
          <SuawLabel weight="bold" label-text="Unit of Length:" size="small" />
          <SuawInputGroup direction="row" :centered="true">
            <SuawRadio id="miles" v-model="editForm.unitSystem" name="chooseUnit" label="Miles" :native-value="'imperial'" />
            <SuawRadio id="kilometers" v-model="editForm.unitSystem" name="chooseUnit" label="Kilometers" :native-value="'metric'" />
          </SuawInputGroup>
        </SuawInputGroup>
      </template>
    </SuawForm>
    <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true">
      <SuawButton size="large" type="secondary-outline" button-text="Cancel" @click="onCancelClicked" />
      <SuawButton size="large" type="primary" button-text="Confirm Changes" @click="onConfirmClicked" />
    </SuawInputGroup>
  </SuawMainContent>
</template>
